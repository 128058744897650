@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-indicator:before {
  content: '';
  background: #00000080;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: ' ';
  position: fixed;
  top: 40%;
  left: 45%;
  z-index: 10010;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #0474bf; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.patient-card-body {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem 1.2rem 1.2rem 1rem;
  gap: 0.5rem;
}
.physician-icon {
  width: 18px;
  height: auto;
}

.patient-card-physician-icon {
  width: 15px;
  height: auto;
  margin-bottom: 5px;
}

input.toggle.toggle-lg.toggle-warning {
  color: #ffc22d;
}

#inner-circle {
  left: 50%;
  top: 38px;
  height: 44px;
  width: 44px;
  background: #ffc22d !important;
  margin: -22px 0 0 -22px;
  z-index: 2;
}

#outer-circle {
  left: -37px;
  height: 75px;
  width: 75px;
  background-color: #00b3f02b !important;
  z-index: 1;
}

.card figure {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}


.chat-bubble.chat-bubble-blue-100 {
  background-color: #DBEAFE;
}

.chat-bubble a {
  text-decoration: underline;
  overflow-wrap: break-word;
}

.chat-bubble.chat-bubble-warning a,
.chat-bubble.chat-bubble-warning a:visited {
  color: #111;
}
.chat-bubble.chat-bubble-secondary a,
.chat-bubble.chat-bubble-secondary a:visited {
  color: #ffc843;
}
.chat-bubble p {
  line-height: 20px;
}

.active-staff-note a {
  color: #377cfb;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: auto;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #c9c9c9;
  border-radius: 10px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__suggestions-container {
  margin-top: 10px;
}

/* .react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: inherit;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
} */

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 6px 20px;
  border-radius: 10px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #dbeafe;
}

.login-notice {
  padding: 30px 0 20px;
}

.login-notice a {
  color: #0e3998;
}
/**Drag and drop styles*/

.filepond--root {
  background-color: #dbeafe;
  border-radius: 15px;
}

.filepond--root.filepond--draggable,
.filepond--root.filepond--dragging {
  background-color: #000;
  border-color: #007bff;
}

/* Style when the FilePond container is in a dragging state */
.filepond--root.filepond--drop-label-active {
  border-color: #007bff;
  background-color: #e0f7fa;
}

/* Customize the label when dragging files over the drop area */
.filepond--root.filepond--label-drip {
  color: #007bff;
}

.filepond--drop-label.filepond--drop-label label {
  color: #000;
}
a.filepond--credits {
  display: none !important;
}
.filepond--root.chat-upload .filepond--list.filepond--list {
  top: 7px;
}

.filepond--file-wrapper.filepond--file-wrapper {
  border: none;
  margin: 0;
  padding: 0;
  min-width: 0;
  height: 100%;
  background-color: #737373;
  border-radius: 7px;
}
span.filepond--label-action {
  color: #06c;
}

.google-icon:before {
  content: ' ';
  background: url('/src/images/google.svg');
  background-size: cover;
  position: absolute;
  margin-top: 0px;
  margin-left: -375px;
  width: 42px;
  height: 42px;
}

.ms-icon:before {
  content: ' ';
  background:url('/src/images/microsoft.svg');
  background-size: cover;
  position: absolute;
  margin-top: 0px;
  margin-left: -375px;
  width: 26px;
  height: 26px;
}

.apple-icon:before {
  content: ' ';
  background:url('/src/images/apple.svg');
  background-size: cover;
  position: absolute;
  margin-top: -3px;
  margin-left: -375px;
  width: 27px;
  height: 27px;
}

.separator {
  width: 100%;
  border-top: 2px solid #dfdfdf;
  text-align: center;
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.2em;
  font-weight: 600;
}

.horz-line {
  width:100%;
  border-top: 2px solid #cdcdcd;
  padding-top: 20px;
}

@media only screen and (min-width: 1300px) {
  #container-circles {
    bottom: 40% !important;
  }
}

@media only screen and (max-width: 600px) {
  .card-body.card-body-small-padding {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.archived-row {
  opacity: 0.6;
  pointer-events: none;
}
.input-disabled,
.input:disabled,
.input[disabled] {
  background-color: #f1f5f9; /* Lighter gray */
  color: #444; /* Lighter text color */
  cursor: not-allowed;
}
